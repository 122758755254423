import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router';
// material
import { Stack, Button, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import ProvidersTable from '../sections/@dashboard/user/ProvidersTable';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';
import '../sections/@dashboard/user/provider.scss';

export const GetProvidersContext = React.createContext();

export default function Provider() {
  const [providers, setProviders] = useState([]);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const getProviders = useCallback(async () => {
    await fetchData(`${baseUrl}/api/admin/get-row-data`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setProviders(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);

  useEffect(() => {
    getProviders();
  }, [getProviders, baseUrl]);

  return (
    <GetProvidersContext.Provider value={{ getProviders }}>
      <Page title="Users">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Providers
            </Typography>
            <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Provider
            </Button>
          </Stack>
          <ProvidersTable providers={providers} />
        </Container>
      </Page>
    </GetProvidersContext.Provider>
  );
}
