import React, { useEffect, useState, useContext } from 'react';
import MuiSwitch from 'src/components/Switch';
import {
  providerCompanyIndustryList,
  providerCompanyTypeList,
  providerSpecialRestrictions,
  companySizeRestrictions,
 
  } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';
import RangeInput from '../../../../../components/RangeInput';

const Restrictions = () => {
  const [data, setData] = useState(null);
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_restrictions: providerData.provider_restrictions,
        provider_industry_restrictions: providerData.provider_industry_restrictions,
        provider_business_filter: providerData.provider_business_filter,
        provider_special_restrictions: providerData.provider_special_restrictions,
      });
    }
  }, [providerData]);

  const handleCheck = async (id, value) => {
    setData({ ...data, [id]: value });
    await updateData('provider', { [id]: value });
  };
  const handleCheck2 = async (id, value) => {
    let tempObj = data.provider_industry_restrictions;
    let newObj = tempObj.filter((i) => i.id === id)[0];
    console.log(newObj, 'NYUOBJECT');
    newObj.on = value;
    setData({ ...data, provider_industry_restrictions: tempObj });
    await updateData('provider', { provider_industry_restrictions: tempObj });
  };
  const handleCheck3 = async (id, value) => {
    let tempObj = data.provider_industry_restrictions;
    let newObj = tempObj.filter((i) => i.id === id)[0];

    newObj.value = value;
    setData({ ...data, provider_industry_restrictions: tempObj });
    await updateData('provider', { provider_industry_restrictions: tempObj });
  };

  const handleChange = async (id, value, max) => {
    if (isNaN(value[0])) {
      value = [0, value[1]];
    }
    if (isNaN(value[1])) {
      value = [value[0], max];
    }
    await updateData('provider', { [id]: value });
  };
  const getObject = (id, array) => {
    //console.log(array, 'ARRAY');
    // return array.filter((e) => e.id === id)[array.filter((e) => e.id === id).length - 1];
    return array.filter((e) => e.id === id)[0];
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            <b>Korlátozás iparágak szerint</b>
            {providerCompanyIndustryList.map((item, i) => {
              let fetchedData = getObject(item.id, data.provider_industry_restrictions);
              // console.log("FETCHED DATA", fetchedData);
              return (
                <div className="Width350" style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.id}`}
                    value={fetchedData?.on ?? false}
                    //value={data[item.modelKey][item.on]}
                    onChange={handleCheck2}
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  />
                  <input
                    type="number"
                    id={`${item.id}`}
                    value={fetchedData?.value ?? 0}
                    onChange={(e) => handleCheck3(e.target.id, e.target.value)}
                    style={{ width: '30px', marginLeft: '6px' }}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <b>Korlátozás cégforma szerint</b>
            {providerCompanyTypeList.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    value={data[item.modelKey][item.id]}
                    onChange={handleCheck}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <b>Korlátozás speciális sajátosságok szerint</b>
            {providerSpecialRestrictions.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    value={data[item.modelKey][item.id]}
                    onChange={handleCheck}
                  />
                </div>
              );
            })}
            <b>Korlátozás cégméret szerint</b>
            {companySizeRestrictions.map((item, i) => {
              return (
                <div key={i}>
                  <RangeInput
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    valueProp={data[item.modelKey][item.id]}
                    onChange={handleChange}
                    max={item.max}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Restrictions;
