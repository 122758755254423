import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Avatar } from '@mui/material';

export const columns = [
  {
    name: 'profileImage',
    label: 'Avatar',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="FlexFullCenter">
            <Avatar
              src={value}
              style={{
                width: '40px',
                height: '40px',
                margin: '0px 12px 0px 12px',
              }}
            />
          </div>
        );
      },
    },
  },
  {
    name: 'companyName',
    label: 'Company name',
    options: {
      filter: true,
      sort: true,
      /*       customBodyRender: (value, tableMeta, updateValue) => {
        return <p>{value}</p>;
      }, */
    },
  },
  {
    name: 'providerID',
    label: 'Provider ID',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'accountEmail',
    label: 'Account email',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'registered',
    label: 'Registered',
    options: {
      filter: true,
      sort: true,
      sortDirection: 'asc',
    },
  },
  {
    name: 'activeSubscription',
    label: 'Active subscription',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="FlexFullCenter">
            {value ? <CheckCircleOutlineIcon sx={{ color: 'green' }} /> : <HighlightOffIcon sx={{ color: 'red' }} />}
          </div>
        );
      },
    },
  },
  {
    name: 'activatedAccount',
    label: 'Activated account',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="FlexFullCenter">
            {value ? <CheckCircleOutlineIcon sx={{ color: 'green' }} /> : <HighlightOffIcon sx={{ color: 'red' }} />}
          </div>
        );
      },
    },
  },
  {
    name: 'completedRegistration',
    label: 'Completed registration',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="FlexFullCenter">
            {value ? <CheckCircleOutlineIcon sx={{ color: 'green' }} /> : <HighlightOffIcon sx={{ color: 'red' }} />}
          </div>
        );
      },
    },
  },
];

