import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button } from '@mui/material';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';
import { TextareaAutosize } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SendIcon from '@mui/icons-material/Send';
import Checkbox from '@mui/material/Checkbox';
import { cafStatusList, aspStatusList, herStatusList } from '../statusHelper';
import Dropdown from '../../../../../components/Dropdown';
import { DateTimePicker } from '@mui/x-date-pickers';

const Contract = ({ statusID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  const [requestData, setRequestData] = useState();
  const [interactionDue, setInteractionDue] = React.useState({ date: dayjs().hour(8).minute(0).second(0).millisecond(0), todo: '', completed: false });
  const [message, setMessage] = useState('');
  const [callNote, setCallNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [mailNote, setMailNote] = useState('');
  const [interactionToDo, setInteractionToDo] = useState([]);
  const [completeLogs, setCompleteLogs] = useState([]);
  const [tempStatus, setTempStatus] = useState({ cafstatus: 'n/a', regstatus: 'n/a', herstatus: 'n/a' });

  const updateStatusStatus = async (reqID, newValue) => {
    await fetchData(`${baseUrl}/api/aspreq/status/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newValue,
    })
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const updateCallLog = async (reqID, newMessage) => {
    await fetchData(`${baseUrl}/api/aspreq/calllog/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newMessage,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const updateEmailOutLog = async (reqID, newMessage) => {
    await fetchData(`${baseUrl}/api/aspreq/mailoutlog/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newMessage,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const updateDueDate = async (reqID, newTodo) => {
    await fetchData(`${baseUrl}/api/aspreq/duedate/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newTodo,
      completed: newTodo.completed || false,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const updateTaskCompleted = async (reqID, taskId, completed) => {
    await fetchData(
      `${baseUrl}/api/aspreq/taskcompleted/${reqID}`, 'PATCH', localStorage.getItem('JWT'),
      {
        taskId,
        completed,
      }
    )
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp);
        reloadData();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  function formatDate(date = new Date()) {
    var d = new Date(date),
      minutes = '' + (d.getMinutes() + 1),
      hours = '' + (d.getHours() + 1),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    // return [year, month, day].join('-');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const getRequestData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setTempStatus(resp.reqstatus);
        setRequestData(resp);
        setInteractionToDo(resp.interacttodo);
        setNotes(resp.messagelog);
        //console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, statusID]);

  const mergeLogs = (phoneLog, emailLog, noteLog, sentMails) => {
    let mergedLog = phoneLog.concat(emailLog);
    let triLog = mergedLog.concat(noteLog);
    let quadLog = triLog.concat(sentMails);
    let sortedLog = quadLog.sort((a, b) => parseInt(new Date(a.date).getTime()) - parseInt(new Date(b.date).getTime()));
    console.log(sortedLog, 'sortedLog');
    setCompleteLogs(sortedLog);
  };

  useEffect(() => {
    requestData &&
      mergeLogs(requestData.phonelog, requestData.emailoutlog, requestData.messagelog, requestData.emaillog);
  }, [requestData]);

  //console.log(tempObj, 'TEMPObj');

  const updateReq = async (reqID, params) => {
    await fetchData(`${baseUrl}/api/aspreq/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      updateParams: params,
    })
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  const reloadData = async () => {
    await getRequestData();
  };

  const callHandler = useCallback(
    (event) => {
      setCallNote(event.target.value);
    },
    [callNote, setCallNote]
  );

  const mailHandler = useCallback(
    (event) => {
      setMailNote(event.target.value);
    },
    [mailNote, setMailNote]
  );

  //console.log(tempObj, 'TEMPOBJECT', tempObj, 'TEMPObj');
  // console.log(requestData.interacttodo, 'interact');
  console.log(requestData, 'requestData');

  return (
    <div>
      {requestData ? (
        <>
          <div className="StatusDetailsLabel">
            <Dropdown
              label="CAF Státusz"
              id="cafstatus"
              list={cafStatusList}
              selValue={requestData.reqstatus.cafstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, cafstatus: value })}
            />
            <Dropdown
              label="ASP Státusz"
              id="aspstatus"
              list={aspStatusList}
              selValue={requestData.reqstatus.aspstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, aspstatus: value })}
            />
            <Dropdown
              label="REG Státusz"
              id="herstatus"
              list={herStatusList}
              selValue={requestData.reqstatus.herstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, herstatus: value })}
            />
            <div style={{ width: '150px', padding: '16px' }}>
              <Button
                onClick={() => updateStatusStatus(requestData._id, tempStatus)}
                style={{ float: 'right' }}
                variant="contained"
              >
                Státusz mentése
              </Button>
            </div>
          </div>
          <div className="interactionGrid marginTop16">
            <div className="interactionChild1 marginTop16">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  format="YYYY-MM-DD HH:mm"
                  label="Következő interakció"
                  showTimeSelect
                  timeFormat="HH:mm"
                  value={dayjs(interactionDue.date)}
                  onChange={(newValue) =>
                    // setInteractionDue((prevValue) => ({ ...prevValue, date: formatDate(newValue) }))
                    setInteractionDue((prevValue) => ({ ...prevValue, date: newValue.toISOString() }))
                  }
                />
              </LocalizationProvider>
              <TextareaAutosize
                style={{ width: '80%', marginTop: '16px' }}
                value={interactionDue.todo}
                onChange={(event) => {
                  setInteractionDue((prevValue) => ({ ...prevValue, todo: event.target.value }));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    updateDueDate(statusID, interactionDue);
                    setInteractionDue({
                      date: dayjs().hour(8).minute(0).second(0).millisecond(0),
                      todo: '',
                    });
                  }
                }}
                maxRows={4}
                minRows={2}
              />
              <Button
                size="large"
                onClick={() => {
                  console.log(interactionDue, 'interactionDue');
                  updateDueDate(statusID, interactionDue);
                  setInteractionDue({ date: dayjs().hour(8).minute(0).second(0).millisecond(0), todo: '' });
                }}
              >
                Dátum mentése
              </Button>
            </div>
            <div className="interactionChild2 marginTop16">
              <p>E-mailekkel kapcs</p>
              <TextareaAutosize
                style={{ width: '80%' }}
                value={mailNote}
                onChange={(event) => {
                  mailHandler(event);
                }}
                maxRows={6}
                minRows={4}
              />
              <Button
                style={{ width: '80%' }}
                size="large"
                onClick={() => {
                  updateEmailOutLog(statusID, mailNote);
                  setMailNote('');
                }}
              >
                Jegyzet rögzítése
              </Button>
            </div>
            <div className="interactionChild3 marginTop16">
              <p>Telefonnal kapcs</p>
              <TextareaAutosize
                style={{ width: '80%' }}
                value={callNote}
                onChange={(event) => {
                  callHandler(event);
                }}
                maxRows={6}
                minRows={4}
              />
              <Button
                style={{ width: '80%' }}
                size="large"
                onClick={() => {
                  updateCallLog(statusID, callNote);
                  setCallNote('');
                }}
              >
                Jegyzet rögzítése
              </Button>
            </div>
            <div className="interactionChild4 marginTop16">
              <p>Andinak Üzenet</p>
              <TextareaAutosize
                style={{ width: '80%' }}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
                maxRows={6}
                minRows={4}
              />
              <Button
                style={{ width: '80%' }}
                size="large"
                onClick={() => {
                  updateReq(statusID, { messageAboutSP: message });
                }}
              >
                Üzenet mentése
              </Button>
            </div>
            <div className="interactionChild5 marginTop16">
              <h2>Következő interakció</h2>
              {requestData.interacttodo.length > 0 && (
                <div className="marginTop16">
                  {/* <p>
                      <br />
                      <span>{requestData.interacttodo[requestData.interacttodo.length - 1].date}</span>{' '}
                      <span>{requestData.interacttodo[requestData.interacttodo.length - 1].todo}</span>
                      <hr />
                    </p> */}
                  {requestData.interacttodo.slice(1).reverse().map((item, index) => {
                    const isValidDate = dayjs(item.date).isValid();
                    return (
                      <div key={index} style={{ marginBottom: '16px' }}>
                        <Checkbox
                          checked={item.completed || false}
                          onChange={(e) => {
                            updateTaskCompleted(statusID, item._id, e.target.checked);
                          }}
                          sx={{
                            cursor: 'pointer',
                            marginRight: '12px',
                            '& .MuiSvgIcon-root': {
                              fontSize: '16px',
                            },
                          }}
                        />
                        <span
                          style={{
                            textDecoration: item.completed ? "line-through" : "none",
                            color: item.completed ? "#888" : "#000",
                          }}
                        >
                          {isValidDate ? dayjs(item.date).format("YYYY-MM-DD HH:mm") : item.date}
                          {' '}
                          {item.todo}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>


            <div className="interactionChild6 marginTop16">
              <h2>Feljegyzések</h2>
              <div className="marginTop16">
                {completeLogs.toReversed().map((e, idx) => {
                  return (
                    <p key={idx}>
                      <br />
                      <span>
                        {e.note ? (
                          <AlternateEmailIcon fontSize="small" />
                        ) : e.selfnote ? (
                          <BorderColorIcon fontSize="small" />
                        ) : e.sender ? (
                          <SendIcon fontSize="small" />
                        ) : (
                          <PhoneIcon fontSize="small" />
                        )}
                      </span>{' '}
                      <span>{formatDate(e.date)}</span>{' '}
                      {e.sender && <span style={{ fontWeight: '800' }}>{e.sender}</span>}{' '}
                      <span>{e.note || e.message || e.sentMail}</span>
                      <hr />
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginTop: '16px' }}> Loading...</div>
      )}
    </div>
  );
};

export default Contract;
